html, body {
	padding: 0;
	margin: .5rem;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	line-height: 1.6;
	font-size: 18px;
	background-color: #5C5C5C;
	color: white;
}

* {
	box-sizing: border-box;
}

a:hover {
	text-decoration: underline;
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	display: block;
}

button {
	background-color: transparent;
	border-radius: 4px;
	border: 2px solid;
	color: white;
	margin-bottom: .4rem;
}

button:disabled, button[disabled] {
	background-color: grey;
	border-color: grey;
	color: white;
}
