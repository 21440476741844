.picture {
	width: 100%;
	margin-top: 1rem;
}

.header {
	display: flex;
	flex-direction: column;
}

.headerImage {
	width: 6rem;
	height: 6rem;
}

.headerHomeImage {
	width: 8rem;
	height: 8rem;
}

.backToHome {
	margin: 3rem 0 0;
}

.albumText {
	width: 100%;
	max-width: 100vh;
	z-index: 10;
}

.parentContainer {
	float: left;
	width: 500px;
	height: 98vh;
	min-width: 75vw;
	max-width: 90vw;
}

.container {
	max-width: 27rem;
	margin: .5rem auto 1rem;
	padding: 0;
	height: 100%;
}

.pictureHolder {
	margin-top: .8rem;
	position: relative;
}

.pictureHolder > * {
	position: absolute;
	left: 0;
	top: 0;
}

.renderCanvas {
	display: none;
}

.renderImg {
	margin-top: .8rem;
	width: 100%;
	display: none;
}

.buttonContainer {
	margin: .5rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.buttonContainer1 {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.startOverButton {
	border-color: red;
}

.leaderboard {
	display: none;
	width: 100%;
	margin-bottom: .8rem;
}

.leaderboard tr:nth-child(odd) {
	background-color: rgba(0, 0, 0, 0.15);
}

.leaderboard td:nth-child(even), .leaderboard th:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.15);
}

.leaderboard img {
	width: 8rem;
}

.leaderboard tr td:nth-child(4) {
	text-align: center;
}

.footer {
	justify-content: center;
	display: flex;
}

.footer a {
	color: white;
}

.renderButton {
	width: 50%;
	height: 3rem;
	border-color: green;
	background-color: green;
	font-weight: bold;
}

.startOverButton {
	height: 1.5rem;
}
